.task-list-container.dragging-over {
    background-color: var(--bs-success-bg-subtle);
}

.task-list-container.dragging-from {
    background-color: var(--bs-warning-bg-subtle);
}

.task-list-container > div {
    min-height: 2.5em;
}

.task-list-container {
    padding: 3px 6px;
    border-radius: 3px;
    transition: background-color 0.2s ease, opacity 0.1s ease;
    user-select: none;
    min-height: 64px;
}

[data-bs-theme=light] .task-list-container {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='3' ry='3' stroke='%23e9ecef' stroke-width='2' stroke-dasharray='5' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .task-list-container {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='3' ry='3' stroke='%23343a40' stroke-width='2' stroke-dasharray='5' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}
