.task-container {
    padding: 3px 0;
    cursor: default;
}

.task {
    background-color: var(--bs-tertiary-bg);
    border-radius: .25rem;
    padding: 3px;
}

.task-body {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
}

.task-body > div {
    overflow-wrap: break-word;
    word-break: break-word;
    padding-right: .4rem;
}

.task-header {
    display: flex;
    align-items: center;
    min-height: 45px; /* the combined height of the div and the small elements inside the task-body */
    cursor: pointer;
}

.task-form {
    padding: .65rem .6rem;
}

.task-form-description {
    margin-bottom: 0
}
